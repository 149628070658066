import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { stripUnit } from 'polished'

import { Colors, Spacing, Types } from '@walter/shared'
import { animationCurve, animationTime } from '../../styles/global'
import { fontWeights } from '../../styles/typography'
import { Icon } from '../Icon'
import { Pill } from '../Pill'

const Container = styled.div`
  display: block;
`

const Trigger = styled.div<{ isPadded: boolean; shouldUseReactHookFormStyling: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.isPadded &&
    css`
      margin: -${Spacing.large};
      padding: ${Spacing.large};
    `}
  ${(props) =>
    props.shouldUseReactHookFormStyling &&
    css`
      width: 100%;
      padding: ${Spacing.large};
      margin: auto;
    `}
`

const IconWrap = styled.div`
  display: flex;
  opacity: 0.85;
  color: ${Colors.greyDark};
  margin-right: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
`

const Main = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: ${Spacing.medium};
`

const Count = styled.span`
  display: flex;
  pointer-events: none;
`

const Content = styled.main<{ isPadded: boolean; shouldUseReactHookFormStyling: boolean }>`
  margin-top: ${Spacing.medium};

  ${(props) =>
    props.isPadded &&
    !props.shouldUseReactHookFormStyling &&
    css`
      margin-top: ${Spacing.large};
    `}
  ${(props) =>
    props.shouldUseReactHookFormStyling &&
    css`
      margin-top: -12px;
    `}
`

const Heading = styled.span`
  color: ${Colors.greyDark};
  font-weight: ${fontWeights.bold};
  margin-right: ${Spacing.small};
  text-align: left;
`

const Chevron = styled.div<{ expanded: any }>`
  margin-left: auto;
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'none')};
  opacity: 0.75;
  transition: opacity ${animationTime} ${animationCurve};

  ${Trigger}:hover &,
  ${Trigger}:focus & {
    opacity: 1;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
`
type AccordionProps = {
  dataTestId?: string
  children: React.ReactNode
  heading: string
  count?: number | string
  icon?: Types.IconName
  isOpen?: boolean
  isPadded?: any
  shouldUseReactHookFormStyling?: boolean
  onCloseOpenChange?: any
  startExpended?: boolean
}

export const Accordion = ({
  dataTestId,
  children,
  heading,
  count,
  icon,
  isOpen,
  isPadded,
  shouldUseReactHookFormStyling,
  onCloseOpenChange,
  startExpended,
}: AccordionProps) => {
  const [expanded, setExpanded] = useState(startExpended)

  // Need that if we want to control the state from parent.
  const handleClickOnExpanded = () => (onCloseOpenChange ? onCloseOpenChange(!isOpen) : setExpanded(!expanded))

  const isExpanded = onCloseOpenChange ? isOpen : expanded
  return (
    <Container>
      <Trigger
        data-test-id={dataTestId + '_Trigger'}
        role="button"
        data-cy={`trigger-${heading}`}
        onClick={handleClickOnExpanded}
        isPadded={isPadded}
        shouldUseReactHookFormStyling={!!shouldUseReactHookFormStyling}
      >
        {icon && (
          <IconWrap>
            <Icon icon={icon as Types.IconName} size="small" />
          </IconWrap>
        )}
        <Main>
          <Heading data-test-id={dataTestId + '_Header'}>{heading}</Heading>
          {(Boolean(count) || count === 0) && (
            <Count>
              <Pill dataTestId={`Pill_Count_${count}`} text={count} />
            </Count>
          )}
        </Main>
        <Chevron expanded={isExpanded ? 'true' : false}>
          <Icon icon="dropdown" />
        </Chevron>
      </Trigger>
      {isExpanded && (
        <Content
          data-test-id={dataTestId + '_Container'}
          isPadded={isPadded}
          shouldUseReactHookFormStyling={!!shouldUseReactHookFormStyling}
        >
          <Inner>{children}</Inner>
        </Content>
      )}
    </Container>
  )
}
