import React from 'react'
import { ResourceList } from '../ResourceList'
import { File } from './File'
import { Folder } from './Folder'
import { FileType, FolderType } from './helpers'
import { EmptyState } from '../EmptyState'
import { t } from '../../utils'

type FoldersAndFilesListProps<Folder extends FolderType, File extends FileType> = {
  folders: Folder[]
  files: File[]
  onFolderClick: (folder: Folder) => void
  onFileClick: (file: File) => void
  preferredLanguage?: string
  onFolderClickEdit?: (folder: Folder) => void
  onFileClickEdit?: (file: File) => void
  isResident?: boolean
  isLoading?: boolean
}

export function FoldersAndFilesList<Folder extends FolderType, File extends FileType>({
  folders,
  files,
  onFolderClick,
  onFolderClickEdit,
  onFileClickEdit,
  onFileClick,
  preferredLanguage,
  isResident,
  isLoading,
}: FoldersAndFilesListProps<Folder, File>) {
  if (!folders.length && !files.length) {
    return (
      <EmptyState
        isLoading={isLoading}
        testID="files-empty"
        heading={isLoading ? t('loading') : t('no-uploaded-files')}
        description={isLoading ? undefined : t('files:no-files-folders')}
      />
    )
  }

  return (
    <ResourceList dataTestId="Files_FolderAndFiles">
      {folders.map((folder) => (
        <Folder
          key={folder.id}
          folder={folder}
          numberOfFilesInIt={folder.files?.length ?? 0}
          onClick={() => onFolderClick(folder)}
          handleClickOnEdit={onFolderClickEdit ? () => onFolderClickEdit(folder) : undefined}
          isShared={!isResident && folder.projects?.length >= 2}
          preferredLanguage={preferredLanguage}
        />
      ))}

      {files.map((file) => (
        <File
          key={file.id}
          file={file}
          onClick={() => onFileClick(file)}
          handleClickOnEdit={onFileClickEdit ? () => onFileClickEdit(file) : undefined}
        />
      ))}
    </ResourceList>
  )
}
