import { SharedUtils } from '@walter/shared'
import isInteger from 'lodash/isInteger'
import { z } from 'zod'
import { i18n, t } from '../../utils'

export const Contacti18nFields = {
  projects: t('selected-project'),
  isPrivate: t('contact-is-private'),
  firstName: t('first-name'),
  lastName: t('last-name'),
  title: t('title'),
  email: t('email'),
  phone: t('phone-number'),
  extension: t('extension'),
  website: t('website'),
}

export const ContactSchema = z.object({
  projects: z
    .array(z.any(), {
      required_error: i18n.t('input:is-required', { field: Contacti18nFields.projects }),
    })
    .min(1, i18n.t('input:is-required', { field: Contacti18nFields.projects })),
  isPrivate: z.boolean().optional().nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  title: z.string().optional().nullable(),
  email: z
    .string()
    .email({ message: t('invalid-email') })
    .or(z.literal('').nullable().optional()),
  phone: z.object({
    number: z
      .string()
      .regex(SharedUtils.phoneNumberRegex, {
        message: i18n.t('input:must-be-phone-number', {
          field: Contacti18nFields.phone,
          formats: '+11231231234, 1231231234',
        }),
      })
      .or(z.literal('').nullable().optional()),
    extension: z
      .string()
      .nullable()
      .optional()
      .refine(
        (value) => {
          return (value?.trim().length ?? 0) > 0 && typeof value === 'string'
            ? isInteger(parseInt(value?.trim()))
            : true
        },
        {
          message: t('invalid-extension-number'),
        },
      ),
  }),
  website: z.string().nullable().optional(),
})

export const contactSchema = ContactSchema.extend({ id: z.string().nullable().optional() })
export type ContactFormType = z.infer<typeof ContactSchema>
