import { z } from 'zod'
import { t } from '../../../utils'

export const thirdPartiesHapiHopemi18nFields = {
  username: t('hopem-hapi-client-identifier-username-please-note-that'),
  password: t('hopem-hapi-client-identifier-password-please-note-that'),
  key: t('hopem-hapi-client-identifier-key'),
  shouldSkipProjectCreation: t('third-party-auto-add-missing-buildings'),
  shouldSyncBuildingComplexesAsOneBuilding: t('third-party-sync-building-complexes-as-one-building'),
  shouldRemoveFamilyMembersAddedByUsers: t('third-party-remove-family-members-added-by-users'),
  shouldRemoveTenantsAddedByUsers: t('third-party-remove-tenants-added-by-users'),
  shouldRemoveOwnersAddedByUsers: t('third-party-remove-owners-added-by-users'),
}

export const thirdPartiesHapiHopemSchemaUpdate = z.object({
  username: z.string().optional(),
  password: z.string().optional(),
  key: z.string().optional(),
  shouldSkipProjectCreation: z.boolean().nullable().default(false),
  shouldSyncBuildingComplexesAsOneBuilding: z.boolean().optional().nullable().default(false),
  shouldRemoveFamilyMembersAddedByUsers: z.boolean().optional().nullable().default(false),
  shouldRemoveTenantsAddedByUsers: z.boolean().optional().nullable().default(false),
  shouldRemoveOwnersAddedByUsers: z.boolean().optional().nullable().default(false),
})

export type ThirdPartiesHapiHopem = z.infer<typeof thirdPartiesHapiHopemSchemaUpdate>
