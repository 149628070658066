import { Api } from '@walter/shared'
import { z } from 'zod'
import { i18n, t } from '../../utils'

export const Rolesi18nFields = {
  title: t('general:title'),
  description: t('general:description'),
  projects: t('general:projects'),
  canOnlySeeOwnTasks: t('can-only-see-own-tasks'),
  shouldAutomaticallyAddNewBuildings: t('should-automatically-add-new-buildings-to-role'),
  permissions: t('functionality-permissions'),
  MANAGING_COMPANY_INFO_READ: t('view-managing-company-information'),
  MANAGING_COMPANY_INFO_WRITE: t('edit-managing-company-information'),
  ROLES_READ: t('view-managing-company-roles'),
  ROLES_WRITE: t('edit-managing-company-roles'),
  ACCOUNTS_READ: t('view-your-profile'),
  ACCOUNTS_WRITE: t('edit-your-profile'),
  NOTIFICATION_READ: t('view-news-memos'),
  NOTIFICATION_WRITE: t('edit-news-memos'),
  CALENDAR_READ: t('view-calendar-events'),
  CALENDAR_WRITE: t('edit-calendar-events'),
  CONTACT_READ: t('view-contacts'),
  CONTACT_WRITE: t('edit-contacts'),
  RULE_READ: t('view-rules'),
  RULE_WRITE: t('edit-rules'),
  AMENITY_READ: t('view-amenities'),
  AMENITY_WRITE: t('edit-amenities'),
  MAIL_READ: t('view-packages'),
  MAIL_WRITE: t('edit-packages'),
  CHAT_READ: t('view-chat-messages'),
  CHAT_WRITE: t('send-chat-messages'),
  USER_READ: t('view-residents-list'),
  USER_WRITE: t('edit-residents-list'),
  TASK_READ: t('view-tasks'),
  TASK_WRITE: t('edit-tasks'),
  FILE_READ: t('view-documents'),
  FILE_WRITE: t('edit-documents'),
  SALE_READ: t('view-marketplace-items'),
  SALE_WRITE: t('edit-marketplace-items'),
  SEGMENT_READ: t('view-filters'),
  SEGMENT_WRITE: t('edit-filters'),
  PROJECT_READ: t('view-project-informations'),
  PROJECT_WRITE: t('edit-project-informations'),
  FAQ_READ: t('view-faq'),
  FAQ_WRITE: t('edit-faq'),
  THIRD_PARTY_SYNCHRONIZATION_WRITE: t('edit-bank-third-party-synchronization-information'),
  THIRD_PARTY_SYNCHRONIZATION_READ: t('view-bank-third-party-synchronization-information'),
  RENT_WRITE: t('edit-rent'),
  RENT_READ: t('view-rent'),
  ACCOUNT_STATEMENT_WRITE: t('edit-account-statement'),
  ACCOUNT_STATEMENT_READ: t('view-account-statement'),
}

export const RolesSchema = z.object({
  title: z
    .string({ required_error: i18n.t('input:is-required', { field: Rolesi18nFields.title }) })
    .min(1, { message: i18n.t('input:is-required', { field: Rolesi18nFields.title }) }),
  description: z.string().nullable().optional(),
  projects: z
    .array(z.object({ id: z.string() }), {
      required_error: i18n.t('input:is-required', { field: Rolesi18nFields.projects }),
    })
    .min(1, i18n.t('input:is-required', { field: Rolesi18nFields.projects })),
  shouldAutomaticallyAddNewBuildings: z.boolean({
    required_error: i18n.t('input:is-required', { field: Rolesi18nFields.title }),
  }),
  canOnlySeeOwnTasks: z.boolean({
    required_error: i18n.t('input:is-required', { field: Rolesi18nFields.title }),
  }),
  permissions: z.array(z.nativeEnum(Api.Permission_Type)),
})

export type Roles = z.infer<typeof RolesSchema>
