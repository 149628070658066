import { Permission_Type } from '../api'

type UserForPermissionsType = { id: string; role: string; managingCompanyRole?: { permissions: string[] } | null }

export const permissions = Object.values(Permission_Type)

export const Module = {
  MANAGING_COMPANY_INFO: 'MANAGING_COMPANY_INFO',
  ROLES: 'ROLES',
  ACCOUNTS: 'ACCOUNTS',
  NOTIFICATION: 'NOTIFICATION',
  NEWS: 'NEWS',
  CALENDAR: 'CALENDAR',
  CONTACT: 'CONTACT',
  RULE: 'RULE',
  AMENITY: 'AMENITY',
  RESERVATIONS: 'RESERVATIONS',
  MAIL: 'MAIL',
  CHAT: 'CHAT',
  USER: 'USER',
  UNITS: 'UNITS',
  DELIVERY: 'DELIVERY',
  WORK_ORDER: 'WORK_ORDER',
  FILE: 'FILE',
  FAQ: 'FAQ',
  SALE: 'SALE',
  SEGMENT: 'SEGMENT',
  PROJECT: 'PROJECT',
  TASK: 'TASK',
  RENT: 'RENT',
  ACCOUNT_STATEMENT: 'ACCOUNT_STATEMENT',
  THIRD_PARTY_SYNCHRONIZATION: 'THIRD_PARTY_SYNCHRONIZATION',
  ACCESS_KEY: 'ACCESS_KEY',
} as const

type ModuleType = typeof Module[keyof typeof Module]

const modulePermissionsMap: { [key in ModuleType]: Permission_Type[] } = {
  MANAGING_COMPANY_INFO: [Permission_Type.ManagingCompanyInfoRead, Permission_Type.ManagingCompanyInfoWrite],
  ROLES: [Permission_Type.RolesRead, Permission_Type.RolesWrite],
  ACCOUNTS: [Permission_Type.AccountsRead, Permission_Type.AccountsWrite],
  NOTIFICATION: [Permission_Type.NotificationRead, Permission_Type.NotificationWrite],
  NEWS: [Permission_Type.NotificationRead, Permission_Type.NotificationWrite],
  CALENDAR: [Permission_Type.CalendarRead, Permission_Type.CalendarWrite],
  CONTACT: [Permission_Type.ContactRead, Permission_Type.ContactWrite],
  RULE: [Permission_Type.RuleRead, Permission_Type.RuleWrite],
  AMENITY: [Permission_Type.AmenityRead, Permission_Type.AmenityWrite],
  RESERVATIONS: [Permission_Type.AmenityRead, Permission_Type.AmenityWrite],
  MAIL: [Permission_Type.MailRead, Permission_Type.MailWrite],
  CHAT: [Permission_Type.ChatRead, Permission_Type.ChatWrite],
  USER: [Permission_Type.UserRead, Permission_Type.UserWrite],
  UNITS: [Permission_Type.UserRead, Permission_Type.UserWrite],
  DELIVERY: [Permission_Type.DeliveryRead, Permission_Type.DeliveryWrite],
  WORK_ORDER: [Permission_Type.WorkOrderRead, Permission_Type.WorkOrderWrite],
  FILE: [Permission_Type.FileRead, Permission_Type.FileWrite],
  FAQ: [Permission_Type.FaqRead, Permission_Type.FaqWrite],
  SALE: [Permission_Type.SaleRead, Permission_Type.SaleWrite],
  SEGMENT: [Permission_Type.SegmentRead, Permission_Type.SegmentWrite],
  PROJECT: [Permission_Type.ProjectRead, Permission_Type.ProjectWrite],
  TASK: [Permission_Type.TaskRead, Permission_Type.TaskWrite],
  RENT: [Permission_Type.RentRead, Permission_Type.RentWrite],
  THIRD_PARTY_SYNCHRONIZATION: [
    Permission_Type.ThirdPartySynchronizationRead,
    Permission_Type.ThirdPartySynchronizationWrite,
  ],
  ACCOUNT_STATEMENT: [Permission_Type.AccountStatementRead, Permission_Type.AccountStatementWrite],
  ACCESS_KEY: [Permission_Type.AccessKeyRead, Permission_Type.AccessKeyWrite],
}

const moduleWritePermissionMap: { [key in ModuleType]: Permission_Type } = {
  MANAGING_COMPANY_INFO: Permission_Type.ManagingCompanyInfoWrite,
  ROLES: Permission_Type.RolesWrite,
  ACCOUNTS: Permission_Type.AccountsWrite,
  NOTIFICATION: Permission_Type.NotificationWrite,
  NEWS: Permission_Type.NotificationWrite,
  CALENDAR: Permission_Type.CalendarWrite,
  CONTACT: Permission_Type.ContactWrite,
  RULE: Permission_Type.RuleWrite,
  AMENITY: Permission_Type.AmenityWrite,
  RESERVATIONS: Permission_Type.AmenityWrite,
  MAIL: Permission_Type.MailWrite,
  CHAT: Permission_Type.ChatWrite,
  USER: Permission_Type.UserWrite,
  UNITS: Permission_Type.UserWrite,
  DELIVERY: Permission_Type.DeliveryWrite,
  WORK_ORDER: Permission_Type.WorkOrderWrite,
  FILE: Permission_Type.FileWrite,
  FAQ: Permission_Type.FaqWrite,
  SALE: Permission_Type.SaleWrite,
  SEGMENT: Permission_Type.SegmentWrite,
  PROJECT: Permission_Type.ProjectWrite,
  TASK: Permission_Type.TaskWrite,
  RENT: Permission_Type.RentWrite,
  THIRD_PARTY_SYNCHRONIZATION: Permission_Type.ThirdPartySynchronizationWrite,
  ACCOUNT_STATEMENT: Permission_Type.AccountStatementWrite,
  ACCESS_KEY: Permission_Type.AccessKeyWrite,
}

export function userCanSeeModule(user: UserForPermissionsType | undefined, moduleType: ModuleType) {
  return hasAtLeastOnePermissions(user, modulePermissionsMap[moduleType])
}

export function userCanEditModule(user: UserForPermissionsType | undefined, moduleType: ModuleType) {
  return hasAtLeastOnePermissions(user, [moduleWritePermissionMap[moduleType]])
}

export const hasAtLeastOnePermissions = (user: UserForPermissionsType | undefined, permissions: string[]) =>
  user &&
  (user.id === 'walter' ||
    (user?.managingCompanyRole
      ? permissions.some((p) => user.managingCompanyRole?.permissions.some((permission) => permission === p))
      : true))

export const hasAllPermissions = (user: UserForPermissionsType | undefined, permissions: string[]) => {
  if (user?.id === 'walter') {
    return true
  }
  if (user?.managingCompanyRole) {
    return permissions.every((permission) => user.managingCompanyRole?.permissions.some((p) => p === permission))
  }
  // True if user doesn't have any managing company role for now
  return true
}

export const hasPermissionsForShared = (currentManagingCompanyProjectIds: string[], projects: string[]) => {
  return projects.every((project) => currentManagingCompanyProjectIds.includes(project))
}

export const hasSomePermissionsForShared = (currentManagingCompanyProjectIds: string[], projects: string[]) => {
  return projects.some((project) => currentManagingCompanyProjectIds.includes(project))
}
