/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Colors, Spacing } from '@walter/shared'
import { WebDateUtils } from '../../utils/date'
import React, { ChangeEvent, useState } from 'react'
import Calendar from 'react-calendar/dist/entry.nostyle'
import styled, { css } from 'styled-components'
import { boxShadowDark } from '../../styles/global'
import { CalendarUtils, t } from '../../utils'
import { useOutsideAlerter } from '../../utils/hooks'
import { Button } from '../Button'
import { ButtonGroup } from '../ButtonGroup'
import { Icon } from '../Icon'
import { Input } from '../Input'
import { DatePickerStyled } from './styles'

type DatePickerPosition = 'top' | 'bottom'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @supports not (-moz-appearance: none) {
    input[type='date'] {
      padding-right: ${Spacing.xLarge};
    }
  }

  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`

const CalendarWrap = styled.div<{ withTime: boolean; position: DatePickerPosition; side: 'left' | 'right' }>`
  position: absolute;
  top: 100%;
  ${(props) => {
    if (props.side === 'left') {
      return css`
        left: 0;
      `
    }
    return css`
      right: 0;
    `
  }}
  z-index: 1;
  background-color: ${Colors.white};
  margin-top: ${Spacing.small};
  border-radius: 5px;

  ${(props) =>
    props.withTime &&
    css`
      margin-bottom: ${Spacing.xLarge};
      padding: ${Spacing.large};
      ${boxShadowDark};
      .react-calendar {
        box-shadow: none;
      }
    `}

  ${(props) =>
    props.position === 'top' &&
    css`
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: ${Spacing.small};
    `}
`

const Arrow = styled.span`
  display: flex;
  padding: ${Spacing.tiny};
`

const ManuallyCenteredDiv = styled.div`
  cursor: pointer;
  z-index: 2;
  position: absolute;
  right: 1px;
  bottom: 1px;
  display: flex;
  padding: ${Spacing.xxSmall};
  background-color: ${Colors.white};
  border-radius: 8px;
`

type DatePickerProps = {
  dataTestId?: string
  label: string
  value: Date | null
  onChange: (date: Date | null) => void
  placeholder?: string
  position?: DatePickerPosition
  withTime?: boolean
  calendarProps?: React.ComponentProps<typeof Calendar>
  preventClose?: boolean
  readOnly?: boolean
  side?: 'left' | 'right'
}

export const DatePicker = React.memo(
  ({
    dataTestId,
    label,
    value,
    onChange,
    placeholder,
    position = 'bottom',
    withTime,
    calendarProps,
    preventClose = false,
    readOnly = true,
    side = 'left',
  }: DatePickerProps) => {
    const [calendarVisible, setCalendarVisible] = useState(false)
    const wrapperRef = React.useRef(null)
    const [textDate, setTextDate] = useState('')
    const dateInputRef = React.useRef<HTMLInputElement>(null)

    useOutsideAlerter(wrapperRef, () => setCalendarVisible(false))

    function handleCalendarChange(newDate: Date) {
      if (withTime) {
        onChange(newDate)
      } else {
        setCalendarVisible(false)
        onChange(newDate)
      }
    }

    function handleClearCalendar() {
      onChange(null)
    }

    function convertHourMinutes(time: string) {
      const [hours, minutes] = time.split(':').map((val) => parseInt(val))

      return WebDateUtils.dayjs(value ?? new Date())
        .set('hours', hours)
        .set('minutes', minutes)
        .toDate()
    }

    function handleClickConfirm() {
      const dateTime = convertHourMinutes(time)

      onChange(dateTime)
      setCalendarVisible(false)
    }

    function handleTimeChange(e: ChangeEvent<HTMLInputElement>) {
      const dateWithTime = convertHourMinutes(e.target.value)

      onChange(dateWithTime)
    }

    const time = React.useMemo(() => {
      return WebDateUtils.format(value || new Date(), 'HH:mm')
    }, [value])

    const formattedTextInputValue = React.useMemo(() => {
      if (!value) {
        return ''
      }

      if (withTime) {
        const dateTime = WebDateUtils.format(value || new Date(), 'YYYY-MM-DD - HH:mm')
        setTextDate(dateTime)
        return dateTime
      }

      const dateTime = WebDateUtils.format(value || new Date(), 'YYYY-MM-DD')
      setTextDate(dateTime)
      return dateTime
    }, [withTime, value])

    return (
      <DatePickerStyled data-cy="pick-a-date">
        <Container data-test-id={`${dataTestId}_DatePicker`}>
          {readOnly ? (
            <Input
              dataTestId={`${dataTestId}_Input`}
              label={label}
              placeholder={placeholder}
              onClick={() => setCalendarVisible(true)}
              value={formattedTextInputValue}
              readOnly
            />
          ) : (
            <Input
              ref={dateInputRef}
              dataTestId={`${dataTestId}_Input`}
              label={label}
              placeholder={placeholder}
              onClick={() => setCalendarVisible(true)}
              value={textDate}
              onChange={(ev) => {
                setTextDate(ev.target.value)
              }}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  setCalendarVisible(false)
                  dateInputRef?.current?.blur()
                }
              }}
              onBlur={() => {
                if (withTime) {
                  onChange(textDate ? convertHourMinutes(time) : null)
                } else {
                  onChange(textDate ? new Date(textDate + 'T00:00:00-05:00') : null)
                }
              }}
              type={'date'}
              {...CalendarUtils.getDatetimeMinMaxValues(withTime)}
            />
          )}
          {calendarVisible && (
            <CalendarWrap
              data-test-id={`${dataTestId}_Calendar`}
              ref={wrapperRef}
              position={position}
              withTime={!!withTime}
              side={side}
              className="animate-in fade-in-0 zoom-in-95"
            >
              <Calendar
                onChange={(newDate) => handleCalendarChange(Array.isArray(newDate) ? newDate[0] : newDate)}
                value={value ?? undefined}
                prevLabel={
                  <Arrow>
                    <Icon icon="left-chevron" size="small" />
                  </Arrow>
                }
                nextLabel={
                  <Arrow>
                    <Icon icon="right-chevron" size="small" />
                  </Arrow>
                }
                {...calendarProps}
              />
              {withTime && (
                <>
                  <div
                    style={{
                      marginTop: Spacing.medium,
                    }}
                  >
                    <Input value={time} type="time" label="Time" onChange={handleTimeChange} />
                  </div>

                  <div
                    style={{
                      marginTop: Spacing.large,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ButtonGroup>
                      <Button testID="cancel-button" size="small" onClick={() => setCalendarVisible(false)}>
                        {t('cancel')}
                      </Button>
                      <Button testID="confirm-button" size="small" theme="primary" onClick={handleClickConfirm}>
                        {t('confirm')}
                      </Button>
                    </ButtonGroup>
                  </div>
                </>
              )}
              <div style={{ paddingTop: Spacing.small }}>
                <Button
                  variant="form"
                  fullwidth
                  onClick={() => {
                    handleCalendarChange(new Date())
                  }}
                >
                  {t('today')}
                </Button>
              </div>
            </CalendarWrap>
          )}
          {!preventClose && !!value ? (
            <ManuallyCenteredDiv onClick={handleClearCalendar}>
              <Icon size={'small'} icon="close" color={Colors.greyLight} />
            </ManuallyCenteredDiv>
          ) : (
            <ManuallyCenteredDiv
              onClick={() => {
                setCalendarVisible(true)
                if (!readOnly) {
                  dateInputRef.current?.focus()
                }
              }}
              style={{ cursor: readOnly ? 'pointer' : 'text' }}
            >
              <Icon size={'small'} icon="close" color={Colors.white} />
            </ManuallyCenteredDiv>
          )}
        </Container>
      </DatePickerStyled>
    )
  },
)
