import { z } from 'zod'
import { i18n, t } from '../../utils'

export const Taski18nFields = {
  associatedProject: t('project'),
  title: t('title'),
  categoryId: t('category'),
  statusId: t('status'),

  assignToIds: t('managers'),
  associateTo: t('associateTo'),

  priorityStatus: t('priority'),
  description: t('description'),

  attachments: t('files'),
  pictures: t('images'),
}

export const taskSchema = z.object({
  associatedProject: z.object(
    {
      id: z.string({ required_error: i18n.t('input:is-required', { field: Taski18nFields.associatedProject }) }),
    },
    {
      required_error: i18n.t('input:is-required', { field: Taski18nFields.associatedProject }),
      invalid_type_error: i18n.t('input:is-required', { field: Taski18nFields.associatedProject }),
    },
  ),
  title: z
    .string({ required_error: i18n.t('input:is-required', { field: Taski18nFields.title }) })
    .min(1, { message: i18n.t('input:is-required', { field: Taski18nFields.title }) }),
  category: z.object(
    {
      id: z
        .string({ required_error: i18n.t('input:is-required', { field: Taski18nFields.categoryId }) })
        .min(1, { message: i18n.t('input:is-required', { field: Taski18nFields.categoryId }) }),
    },
    {
      required_error: i18n.t('input:is-required', { field: Taski18nFields.categoryId }),
      invalid_type_error: i18n.t('input:is-required', { field: Taski18nFields.categoryId }),
    },
  ),
  status: z.object(
    {
      id: z
        .string({ required_error: i18n.t('input:is-required', { field: Taski18nFields.statusId }) })
        .min(1, { message: i18n.t('input:is-required', { field: Taski18nFields.statusId }) }),
    },
    {
      invalid_type_error: i18n.t('input:is-required', { field: Taski18nFields.statusId }),
      required_error: i18n.t('input:is-required', { field: Taski18nFields.statusId }),
    },
  ),
  assignedToIds: z.array(z.object({ id: z.string() })).optional(),
  associatedProperties: z.array(z.object({ id: z.string() })).optional(),
  associatedResidents: z.array(z.object({ id: z.string() })).optional(),
  associatedSegments: z.array(z.object({ id: z.string() })).optional(),
  priorityStatus: z.object({ id: z.string() }).optional(),
  description: z.string().optional(),
  attachments: z.any().optional(),
  pictures: z.any().optional(),
})

export type TaskFormSchema = z.infer<typeof taskSchema>
