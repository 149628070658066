/* eslint-disable react/no-unknown-property */
import { ErrorMessage } from '@hookform/error-message'
import { ErrorMessage as ErrorMessageComponent } from '../../ErrorMessage'
import { Colors, Spacing } from '@walter/shared'
import { WebDateUtils } from '../../../utils/date'
import React, { ChangeEvent, useState } from 'react'
import Calendar from 'react-calendar/dist/entry.nostyle'
import { useFormContext, useWatch } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { boxShadowDark } from '../../../styles/global'
import { CalendarUtils, i18n, t } from '../../../utils'
import { useOutsideAlerter } from '../../../utils/hooks'
import { Button } from '../../Button'
import { ButtonGroup } from '../../ButtonGroup'
import { Icon } from '../../Icon'
import { Input } from '../../Input'
import { DatePickerStyled } from './styles'

export type DatePickerPosition = 'top' | 'bottom'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @supports not (-moz-appearance: none) {
    input[type='date'] {
      padding-right: ${Spacing.xLarge};
    }
  }

  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`

const CalendarWrap = styled.div<{ withTime: boolean; position: DatePickerPosition }>`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: ${Colors.white};
  margin-top: ${Spacing.small};

  ${(props) =>
    props.withTime &&
    css`
      margin-bottom: ${Spacing.xLarge};
      padding: ${Spacing.large};
      ${boxShadowDark};
      .react-calendar {
        box-shadow: none;
      }
    `}

  ${(props) =>
    props.position === 'top' &&
    css`
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: ${Spacing.small};
    `}
  
  .react-calendar__month-view__days button:disabled {
    color: ${Colors.greyLight};
  }
`

const Arrow = styled.span`
  display: flex;
  padding: ${Spacing.tiny};
`

const ManuallyCenteredDiv = styled.div`
  cursor: pointer;
  z-index: 2;
  position: absolute;
  right: 1px;
  bottom: 1px;
  display: flex;
  padding: ${Spacing.xxSmall};
  background-color: ${Colors.white};
  border-radius: 8px;
`

type DatePickerProps = {
  dataTestId?: string
  name: string
  label: string
  value: Date | null
  placeholder?: string
  position?: DatePickerPosition
  withTime?: boolean
  calendarProps?: any // https://github.com/wojtekmaj/react-calendar/blob/master/src/Calendar.jsx
  minDate?: Date
  maxDate?: Date
  isClearable?: boolean
  disabled?: boolean
  errors:
    | {
        [x: string]: any
      }
    | undefined
  preferedLanguage?: string
  additionalOnChange?: (date?: Date) => void
}

export const DatePicker = React.memo(
  ({
    dataTestId,
    name,
    label,
    value,
    placeholder,
    position = 'bottom',
    withTime,
    calendarProps,
    minDate,
    maxDate,
    isClearable = false,
    disabled = false,
    errors,
    preferedLanguage = 'fr',
    additionalOnChange,
  }: DatePickerProps) => {
    const [calendarVisible, setCalendarVisible] = useState(false)
    const wrapperRef = React.useRef(null)
    const { setValue } = useFormContext()
    const [textDate, setTextDate] = useState('')
    const dateInputRef = React.useRef<HTMLInputElement>(null)
    const internalValue = useWatch({ name })
    if (value) setValue(name, value)

    useOutsideAlerter(wrapperRef, () => setCalendarVisible(false))

    function handleCalendarChange(newDate: Date) {
      additionalOnChange && additionalOnChange(newDate)
      setValue(name, newDate)
      if (withTime) {
        if (!disabled) {
          setTextDate(WebDateUtils.format(newDate, `YYYY-MM-DDT${preferedLanguage.includes('fr') ? 'HH' : 'hh'}:mm`))
        }
      } else {
        if (!disabled) {
          setTextDate(WebDateUtils.format(newDate, 'YYYY-MM-DD'))
        }
        setCalendarVisible(false)
      }
    }

    function handleClearCalendar() {
      setTextDate('')
      setValue(name, null)
    }

    function convertHourMinutes(time: string) {
      const [hours, minutes] = time?.length > 0 ? time.split(':').map((val) => parseInt(val)) : [0, 0]

      return WebDateUtils.dayjs(internalValue ?? new Date())
        .set('hours', hours)
        .set('minutes', minutes)
        .toDate()
    }

    function handleClickConfirm() {
      const dateTime = convertHourMinutes(time)

      setValue(name, dateTime)
      setCalendarVisible(false)
    }

    function handleTimeChange(e: ChangeEvent<HTMLInputElement>) {
      const dateWithTime = convertHourMinutes(e.target.value)
      additionalOnChange && additionalOnChange(dateWithTime)
      if (!disabled) {
        setTextDate(WebDateUtils.format(dateWithTime, `YYYY-MM-DDT${preferedLanguage.includes('fr') ? 'HH' : 'hh'}:mm`))
      }
      setValue(name, dateWithTime)
    }

    const time = React.useMemo(() => {
      if (typeof internalValue === 'string') {
        return WebDateUtils.format(new Date(internalValue) || new Date(), 'HH:mm')
      }

      return WebDateUtils.format(internalValue || new Date(), 'HH:mm')
    }, [internalValue])

    const formattedTextInputValue = React.useMemo(() => {
      if (!internalValue) {
        return ''
      }

      if (withTime) {
        if (typeof internalValue === 'string') {
          const newDate = WebDateUtils.format(
            new Date(internalValue) || new Date(),
            `YYYY-MM-DDT${preferedLanguage.includes('fr') ? 'HH' : 'hh'}:mm`,
          )
          setTextDate(newDate)
          return newDate
        }
        const newDate = WebDateUtils.format(
          internalValue || new Date(),
          `YYYY-MM-DDT${preferedLanguage.includes('fr') ? 'HH' : 'hh'}:mm`,
        )
        setTextDate(newDate)
        return newDate
      }

      if (typeof internalValue === 'string') {
        const newDate = WebDateUtils.format(new Date(internalValue) || new Date(), 'YYYY-MM-DD')
        setTextDate(newDate)
        return newDate
      }

      const newDate = WebDateUtils.format(internalValue || new Date(), 'YYYY-MM-DD')
      setTextDate(newDate)
      return newDate
    }, [withTime, internalValue])

    function openCalendarWithMaybeTextDate() {
      setCalendarVisible(true)
      if (!textDate && !disabled) {
        if (!formattedTextInputValue) {
          const now = new Date()
          const defaultValue = minDate && WebDateUtils.isAfter(minDate, now) ? minDate : now

          setTextDate(
            WebDateUtils.format(
              defaultValue,
              withTime ? `YYYY-MM-DDT${preferedLanguage.includes('fr') ? 'HH' : 'hh'}:mm` : 'YYYY-MM-DD',
            ),
          )
          setValue(name, defaultValue)
        } else {
          setTextDate(formattedTextInputValue)
        }
      }
    }

    return (
      <DatePickerStyled data-cy="pick-a-date">
        <Container data-test-id={dataTestId}>
          {textDate == null || !formattedTextInputValue ? (
            <Input
              dataTestId={`${dataTestId}_Input`}
              label={label}
              placeholder={placeholder}
              onFocus={() => {
                if (!disabled) {
                  openCalendarWithMaybeTextDate()
                }
              }}
              onClick={(ev: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                if (!disabled && document.activeElement === ev.currentTarget) {
                  openCalendarWithMaybeTextDate()
                  if (!disabled) {
                    ev.currentTarget.select()
                  }
                }
              }}
              value={
                formattedTextInputValue && withTime
                  ? WebDateUtils.format(new Date(formattedTextInputValue), 'YYYY-MM-DD, HH:mm')
                  : formattedTextInputValue
              }
              readOnly
            />
          ) : (
            <Input
              ref={dateInputRef}
              dataTestId={`${dataTestId}_Input`}
              label={label}
              placeholder={placeholder}
              value={textDate}
              onClick={(ev) => {
                if (document.activeElement === ev.currentTarget) {
                  setCalendarVisible(true)
                  ev.currentTarget.select()
                }
              }}
              onChange={(ev) => {
                setTextDate(ev.target.value)
              }}
              onBlur={() => {
                if (textDate !== formattedTextInputValue && isValidDate(textDate)) {
                  if (withTime) {
                    setValue(name, new Date(textDate), { shouldDirty: true })
                  } else {
                    setValue(name, new Date(textDate + 'T00:00:00-05:00'), { shouldDirty: true })
                    setCalendarVisible(false)
                  }
                }
              }}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault()
                  dateInputRef.current?.blur()
                }
              }}
              onFocus={() => {
                setCalendarVisible(true)
              }}
              type={withTime ? 'datetime-local' : 'date'}
              {...CalendarUtils.getDatetimeMinMaxValues(withTime)}
            />
          )}
          {calendarVisible && (
            <CalendarWrap ref={wrapperRef} position={position} withTime={!!withTime}>
              <Calendar
                locale={i18n.language}
                onChange={(newDate) => handleCalendarChange(Array.isArray(newDate) ? newDate[0] : newDate)}
                value={value ?? internalValue ? new Date(internalValue) : null}
                minDate={minDate}
                maxDate={maxDate}
                prevLabel={
                  <Arrow data-test-id={`${dataTestId}_Left_Button`}>
                    <Icon icon="left-chevron" size="small" />
                  </Arrow>
                }
                nextLabel={
                  <Arrow data-test-id={`${dataTestId}_Right_Button`}>
                    <Icon icon="right-chevron" size="small" />
                  </Arrow>
                }
                {...calendarProps}
              />
              {withTime && (
                <>
                  <div
                    css={`
                      margin-top: ${Spacing.medium};
                    `}
                  >
                    <Input
                      dataTestId={`${dataTestId}_Time_Input`}
                      value={time}
                      type="time"
                      label="Time"
                      onChange={handleTimeChange}
                    />
                  </div>

                  <div
                    css={`
                      margin-top: ${Spacing.large};
                      display: flex;
                      justify-content: flex-end;
                    `}
                  >
                    <ButtonGroup>
                      <Button
                        dataTestId={`${dataTestId}_Cancel_Button`}
                        testID="cancel-button"
                        size="small"
                        onClick={() => setCalendarVisible(false)}
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        dataTestId={`${dataTestId}_Confirm_Button`}
                        testID="confirm-button"
                        size="small"
                        theme="primary"
                        onClick={handleClickConfirm}
                      >
                        {t('confirm')}
                      </Button>
                    </ButtonGroup>
                  </div>
                </>
              )}
            </CalendarWrap>
          )}
          {isClearable && formattedTextInputValue ? (
            <ManuallyCenteredDiv onClick={handleClearCalendar}>
              <Icon size={'small'} icon="close" color={Colors.greyLight} />
            </ManuallyCenteredDiv>
          ) : (
            <ManuallyCenteredDiv
              onClick={openCalendarWithMaybeTextDate}
              style={{ cursor: disabled ? 'pointer' : 'text' }}
            >
              <Icon size={'small'} icon="close" color={Colors.white} />
            </ManuallyCenteredDiv>
          )}
        </Container>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <ErrorMessageComponent data-cy="error-message">{message}</ErrorMessageComponent>}
        />
      </DatePickerStyled>
    )
  },
)

function isValidDate(textDate: string) {
  return !isNaN(new Date(textDate).getTime())
}
